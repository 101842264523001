// import React, { useState } from "react";
import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner";
import axios from "axios";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/DashboardSlider.css";
import { baseURL } from "../services/Aut.post.js";
import GameImg from "./images/game-img-1.png";
import { Link, useNavigate } from "react-router-dom";


function GameStepTwo() {
  const [contestUserEligibility, setContestUserEligibility] = useState();
  const [alreadyGamePalyed, setAlreadyGamePalyed] = useState();
  const contest_name = JSON.parse(localStorage.getItem("contest_name"));
  const sponcer_name = JSON.parse(localStorage.getItem("sponcer_name"));
  const gamedemolink = JSON.parse(localStorage.getItem("game_demo_link"));

  const winningtype = JSON.parse(localStorage.getItem("winningtype"));
  const prize = JSON.parse(localStorage.getItem("prize"));
  const AnnouncementDate = JSON.parse(localStorage.getItem("AnnouncementDate"));
  const No_of_winners = JSON.parse(localStorage.getItem("No_of_winners"));
  const stageno = JSON.parse(localStorage.getItem("stageno"));
  const moves = JSON.parse(localStorage.getItem("moves"));
  const time = JSON.parse(localStorage.getItem("time"));
  

  const urlParams = new URLSearchParams(window.location.search);
  const gameValue = urlParams.get('contest');
 
  const navigate = useNavigate();

  useEffect(() => {
   
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/userapi/getcontests/`, null, { headers });
        // setContestData(response.data); // Update the state with fetched data
        console.log(response.data);
        const updatedData = response.data.map(contest => {
          if (contest.contestID === gameValue) {
            console.log("sdfsd")
            console.log(contest.user_eligibility )
            setContestUserEligibility(contest.user_eligibility)
            setAlreadyGamePalyed(contest.alreadyGamePalyed)
          }
          // If contestID does not match, return the contest as is
          return contest;
        });
      } catch (error) {
        console.error('Error fetching contests:', error);
        // Handle error gracefully
      }
    };
  
    fetchData(); // Call the fetchData function
  }, []); // Include accessToken in the dependency array

  const handleClick = () => {
    const gameTandcChecked = document.getElementById("gameTandc").checked;
    const bigbTandcChecked = document.getElementById("bigbTandc").checked;
    if (gameTandcChecked && bigbTandcChecked) {
    
    }else{
      alert("Please check both Game Terms & Conditions and BigBonanza Terms & Conditions.");
      return false;
    }
    const fetchGameSettings = async () => {
      try {
          const response = await axios.get(`${baseURL}/userapi/getcontestssettings/`);
          console.log(response.data)
      
          localStorage.setItem('gridcount', response.data.grid);
      } catch (error) {
          console.error('Error fetching game settings:', error);
      }
  };
  
  fetchGameSettings(); // Fetch dynamic settings when the component mounts

    // Redirect to another page
    navigate(`/Gamep?contest=${gameValue}&stage=1`);
  };
  
  const [contestData, setContestData] = useState([]);

  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const whyBestCard = contestData.map((aboutObj) => {
    return (
      <div className="border-1" key={1}>
        <a href={`/Gamep?game=${aboutObj.id}`}>
          <img src={`${baseURL}${aboutObj.banner_image}`} alt="contest" />
        </a>
        <h3>{aboutObj.contest_name}</h3>
        <p>
          Tockens : <s>{aboutObj.cost_of_contest} </s>
          {aboutObj.cost_of_discount_contest}
        </p>
      </div>
    );
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${baseURL}/userapi/  /`,
          null,
          { headers }
        );
        setContestData(response.data); // Update the state with fetched data
      } catch (error) {
        console.error("Error fetching contests:", error);
        // Handle error gracefully
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Include accessToken in the dependency array

  const fetchGameSettings = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/userapi/usersingupvaluesview/`
      );
      console.log(response.data);

      localStorage.setItem("gridcount", response.data.grid);
    } catch (error) {
      console.error("Error fetching game settings:", error);
    }
  };
  useEffect(() => {
  
    
    fetchGameSettings(); // Fetch dynamic settings when the component mounts
  }, []);

  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper paragraph-1">
          <h1>{contest_name}</h1>
          <h6>Sponsored by {sponcer_name}</h6>
          <div className="DashboardSlider">
            
            <div className="video-wrap-main">
              <div className="video-wrap-1">
              <p>
              {prize}
            </p>

              <ul>
              <li>
              winningtype : {winningtype}
              </li>
              <li>
              AnnouncementDate : {AnnouncementDate}
              </li>
              <li>
              Total No of Winners: {No_of_winners}
              </li>
  <li>no of stages:{stageno}</li>
<li>no of lives :{moves}</li>

            </ul>

              </div>
              <div className="video-wrap-2">
                <h4>How to Play</h4>
              <iframe width="100%" height="315" src={gamedemolink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

              </div>
            </div>
            

            <div className="TermsConditionsBox">
  <div className="form-group">
    <input type="checkbox" id="gameTandc" />
    <label htmlFor="gameTandc">Game Terms & Conditions</label>
  </div>
</div>

<div className="TermsConditionsBox">
  <div className="form-group">
    <input type="checkbox" id="bigbTandc" />
    <label htmlFor="bigbTandc">BigBonanza Terms & Conditions</label>
  </div>
</div>


            <div className="text-center pt-3 pb-5">
            {/* <Link to={`/Gamep?game=${gameval}`} className="btn-2">
          
                {" "}
                Launch
              </Link> */}
       <div>

      {contestUserEligibility === 0 ? (
         <button className="btn-2">You don't have enough tokens to participate in this contest</button>
      ) :alreadyGamePalyed === true ? (
        <button className="btn-2">You are alreday participate in this contest</button>
      ): (
        <button onClick={handleClick} className="btn-2">Launch</button>
      )}




    </div>
     
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameStepTwo;
