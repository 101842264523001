import React, { useEffect, useState } from "react";
import InnerBanner from "./InnerBanner";
import { Link } from "react-router-dom";
import "./Styles/ContactUs.css";
import { Form, Alert } from "react-bootstrap";
import axios from "axios";
import {baseURL } from "../services/Aut.post.js";

function ContactUs() {

 
    // Form state to store input values
    const [formData, setFormData] = useState({
      fullname: '',
      email: '',
      phonenumber: '',
      msg: ''
    });
  
    // Error state to handle form validation
    const [errors, setErrors] = useState({});
    const [errorsumsg, setErrorsumsg] = useState();
    // Handle form input changes
    const handleInputChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    // Form validation function
    const validate = () => {
      const newErrors = {};
      if (!formData.fullname) newErrors.fullname = "Name is required.";
      if (!formData.email) {
        newErrors.email = "Email is required.";
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = "Email is invalid.";
      }
      if (!formData.phonenumber) newErrors.phonenumber = "Phone number is required.";
      if (!formData.msg) newErrors.msg = "Message is required.";
  
      return newErrors;
    };
  
    // Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      const validationErrors = validate();
  
      // If there are validation errors, set the errors state
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        setErrors({}); // Clear any existing errors
  
        // If no validation errors, send data to API
        try {
          const response = await axios.post(`${baseURL}/userapi/submit-contact-form/`, formData);
          console.log('Form submitted successfully:', response.data);
          setErrorsumsg("Form submitted successfully")
          // Handle success (e.g., show a success message or redirect)
        } catch (error) {
          console.error('Error submitting form:', error);
          // Handle error (e.g., show an error message)
        }
      }
    };

  




  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <h1>Contact US </h1>

<div className="contact-address-wrap">
  <div className="contact-address-1">
  <div>
         
            <h4>Address:</h4> 5/12, PKpalli,<br></br>Prakasham, Andhra Pradesh,<br></br>
            Pin code: 523211
          </div>


  </div>
  <div className="contact-address-2">
    
<div id="returnmsg">{errorsumsg}</div>

    <div>
            
    <form onSubmit={handleSubmit}>
      <div className="Contact-form-wrap">
        <div className="reg-1">
          <b>Name</b>
          <input
            type="text"
            name="fullname"
            className="text-f-1"
            value={formData.fullname}
            onChange={handleInputChange}
          />
          {errors.fullname && <p className="error">{errors.fullname}</p>}
        </div>

        <div className="reg-1">
          <b>E-mail</b>
          <input
            type="text"
            name="email"
            className="text-f-1"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>

        <div className="reg-1 w-100">
          <b>Phone</b>
          <input
            type="text"
            name="phonenumber"
            className="text-f-1"
            value={formData.phonenumber}
            onChange={handleInputChange}
          />
          {errors.phonenumber && <p className="error">{errors.phonenumber}</p>}
        </div>

        <div className="reg-1 w-100">
          <b>Message</b>
          <textarea
            className="text-f-1"
            name="msg"
            value={formData.msg}
            onChange={handleInputChange}
          />
          {errors.msg && <p className="error">{errors.msg}</p>}
        </div>

        <div className="reg-1 w-100 text-center">
          <button className="btn-2" type="submit">
            Submit
          </button>
          &nbsp;&nbsp;
          <Link to="/MyAccountStatus">
            <button className="btn-1" type="button">
              Cancel
            </button>
          </Link>
        </div>
      </div>
    </form>
          </div>

  </div>
</div>
          
          

         
        </div>
        <div>

          
        </div>
      </div>
    </div>
  );
}
export default ContactUs;
