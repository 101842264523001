import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Footer.css';
import FooterArrow from '../images/footer-arrow.png';
import { baseURL } from '../../services/Aut.post.js';

function Footer() {
  const [footertext, setFooterText] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // Get the navigate function

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const imageClasses = `rotate ${isVisible ? 'rotated' : ''} cursor-pointer`;

  useEffect(() => {
    const getFooterText = async () => {
      const headers = {
        'Content-Type': 'application/json',
      };

      try {
        const response = await axios.get(`${baseURL}/userapi/getsitetext/`, { headers });
        setFooterText(response.data.GetFtext);
        return response.data;
      } catch (error) {
        console.error('Error fetching user account status:', error);
        throw error;
      }
    };
    getFooterText();
  }, []);

  const handleClick = (path) => (e) => {
    e.preventDefault(); // Prevent default behavior

    if (location.pathname === '/Gamep') { // Check if current path is /Gamep
      const confirmNavigation = window.confirm('Are you sure you want to exit the game?');

      if (confirmNavigation) {
        navigate(path); // Navigate to the clicked page if confirmed
      }
      // If canceled, do nothing and stay on the same page
    } else {
      navigate(path); // Navigate to the clicked page if not on /Gamep
    }
  };

  return (
    <div className='relative-1'>
      <div className='footer-bg'>
        <div className='FooterArrow'>
          <img
            src={FooterArrow}
            alt="Toggle Image"
            onClick={toggleVisibility}
            className={imageClasses}
          />
        </div>

        <div className='footerlinks'>
          <ul>
            <li>
              <Link to="/Subscriptions" onClick={handleClick('/Subscriptions')}>Subscriptions</Link>
            </li>
            <li>
              <Link to="/AboutUs" onClick={handleClick('/AboutUs')}>About Us</Link>
            </li>
            <li>
              <Link to="/ContactUs" onClick={handleClick('/ContactUs')}>Contact Us</Link>
            </li>
            <li>
              <Link to="/PrivacyPolicy" onClick={handleClick('/PrivacyPolicy')}>Privacy Policy</Link>
            </li>
            <li>
              <Link to="/TermsandConditions" onClick={handleClick('/TermsandConditions')}>Terms and Conditions</Link>
            </li>
          </ul>
        </div>

        <div>
          {footertext}
        </div>
      </div>
    </div>
  );
}

export default Footer;
